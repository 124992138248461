/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M4.8.727A.72.72 0 0 1 5.511 0h4.978a.72.72 0 0 1 .711.727v.364h1.778c.785 0 1.422.651 1.422 1.454V7.29a.711.711 0 0 1-1.422 0V2.545H11.2v.364a.72.72 0 0 1-.711.727H5.51A.72.72 0 0 1 4.8 2.91v-.364H3.022v12h2.65a.727.727 0 1 1 0 1.455h-2.65c-.785 0-1.422-.651-1.422-1.454v-12c0-.804.637-1.455 1.422-1.455H4.8zm4.978 1.455H6.222v-.727h3.556z"
            clipRule="evenodd"
        />
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M8.245 9.845a.836.836 0 0 1 1.182 0l1.773 1.773 1.773-1.773a.836.836 0 0 1 1.182 1.182L12.382 12.8l1.773 1.773a.836.836 0 0 1-1.182 1.182L11.2 13.982l-1.773 1.773a.836.836 0 0 1-1.182-1.182l1.773-1.773-1.773-1.773a.836.836 0 0 1 0-1.182"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgOrderCancelled: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-order-cancelled", props.className)} />
));

export default SvgOrderCancelled;

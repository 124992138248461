import { Button, ResultProps } from "antd";
import { Link } from "react-router-dom";

import { defaultErrorMessage } from "../i18n";
import { getRoute, RoutePathName } from "../routes";

type SomeResultProps = Pick<ResultProps, "status" | "title" | "subTitle" | "extra">;

export const getResultPropsFromStatus = (status?: string | number): SomeResultProps => {
    const statusCode = typeof status === "number" ? status : parseInt(status ?? "", 10);
    const result: SomeResultProps = {
        status: "error",
        title: "Erreur",
        subTitle: defaultErrorMessage,
    };

    if (`${statusCode}`.length === 3 && `${statusCode}`.startsWith("5")) {
        result.status = "500";
        result.title = "Erreur serveur";
    } else if (statusCode === 403 || statusCode === 401) {
        result.status = "403";
        result.title = "Erreur d'authentification";
        result.subTitle =
            statusCode === 403
                ? "Nous sommes désolés, vous n'êtes pas autorisé à accéder à cette ressource."
                : "Nous sommes désolés, vous devez être authentifié pour accéder à cette ressource.";
        result.extra = (
            <Link to={getRoute(RoutePathName.login)}>
                <Button type="primary">Connexion</Button>
            </Link>
        );
    } else if (statusCode === 404) {
        result.status = "404";
        result.title = "Non trouvé";
        result.subTitle = "Nous sommes désolés, cette ressource n'a pas été trouvée.";
    } else {
        result.status = "error";
    }

    return result;
};

import constants from "../config/constants";
import { HttpError } from "./errors";

declare global {
    interface Window {
        fbAsyncInit: any;
    }
}

export const loadFacebookSDK = () => {
    ((d, s: "script", id) => {
        // Load the SDK asynchronously
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
            return;
        }
        const js = d.createElement(s);
        js.id = id;
        js.async = true;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode?.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    window.fbAsyncInit = () => {
        FB.init({
            appId: constants.FACEBOOK_APP_ID,
            autoLogAppEvents: true,
            xfbml: true,
            version: "v7.0",
        });
    };
};

export const fbLogin = async () =>
    await new Promise<fb.StatusResponse>((resolve, reject) => {
        if (window.FB) {
            window.FB.login(
                (response) => {
                    if (response.status === "connected") {
                        resolve(response);
                    } else {
                        reject(new HttpError("FB login status failed", 999, response));
                    }
                },
                { scope: "email,public_profile" }
            );
        } else {
            throw new HttpError("FB SDK not initialized", 999);
        }
    });

export const fbInfos = async () =>
    await new Promise((resolve, reject) => {
        if (window.FB) {
            window.FB.api(
                "/me",
                {
                    fields: "first_name,last_name,email,picture",
                },
                (response: any) => {
                    if (response && !response.error) {
                        resolve(response);
                    } else {
                        reject(new HttpError("FB /me failed", 999, response));
                    }
                }
            );
        } else {
            throw new HttpError("FB SDK not initialized", 999);
        }
    });

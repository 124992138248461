import { VFC } from "react";
import { Result, ResultProps } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

import { getResultPropsFromStatus } from "../helpers/apiResult";

interface ApiResultProps extends Omit<ResultProps, "status"> {
    status?: string | number;
    inline?: boolean;
}

const ApiResult: VFC<ApiResultProps> = ({ status, inline }) => {
    const resultProps = getResultPropsFromStatus(status);

    if (inline) {
        return (
            <div className="text-16 font-medium">
                <CloseCircleOutlined className="text-24 text-red mr-16" /> {resultProps.title} {resultProps.subTitle}
            </div>
        );
    }

    return <Result {...resultProps} />;
};

export default ApiResult;

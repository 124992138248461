import { AxiosError } from "axios";
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";

import {
    create,
    details,
    list,
    ValueListItemCreatePayload,
    ValueListItemIdPayload,
    ValueListItemListPayload,
    ValueListItemListResponse,
    ValueListItemUpdatePayload,
    remove,
    update,
} from "./api/valueListItems";
import { ValueListItem } from "./api/types";

export const valueListItemsKeys = {
    all: ["valueListItems"],
    lists: () => [...valueListItemsKeys.all, "list"],
    list: (params?: ValueListItemListPayload) => [...valueListItemsKeys.lists(), params],
    details: () => [...valueListItemsKeys.all, "details"],
    detail: (id?: ValueListItemIdPayload) => [...valueListItemsKeys.details(), id],
};

export const useValueListItemList = <TData = ValueListItemListResponse>(
    params: ValueListItemListPayload,
    options?: UseQueryOptions<ValueListItemListResponse, AxiosError, TData>
) => {
    return useQuery<ValueListItemListResponse, AxiosError, TData>(
        valueListItemsKeys.list(params),
        async () => await list(params),
        { keepPreviousData: true, ...options }
    );
};

export const useValueListItemDetails = <TData = ValueListItem>(
    params: ValueListItemIdPayload,
    options?: UseQueryOptions<ValueListItem, AxiosError, TData>
) => {
    return useQuery<ValueListItem, AxiosError, TData>(
        valueListItemsKeys.detail(params),
        async () => await details(params),
        options
    );
};

export const useValueListItemCreate = (
    options?: UseMutationOptions<ValueListItem, AxiosError, ValueListItemCreatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<ValueListItem, AxiosError, ValueListItemCreatePayload>(async (params) => await create(params), {
        ...options,
        onSuccess: (...args) => {
            options?.onSuccess?.(...args);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(valueListItemsKeys.lists());
        },
    });
};

export const useValueListItemUpdate = (
    options?: UseMutationOptions<ValueListItem, AxiosError, ValueListItemUpdatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<ValueListItem, AxiosError, ValueListItemUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(valueListItemsKeys.detail(variables));
            queryClient.invalidateQueries(valueListItemsKeys.lists());
        },
    });
};

export const useValueListItemRemove = (options?: UseMutationOptions<undefined, AxiosError, ValueListItemIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, ValueListItemIdPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(valueListItemsKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(valueListItemsKeys.lists());
        },
    });
};

import { QueryFunction } from "@tanstack/react-query";

import api from "./apiClient";
import { SearchPaginationQuery } from ".";
import { Notification, ListResponse } from "./types";

// Controller Interface

export type NotificationUpdatePayload = Partial<Notification>;

export type NotificationIdPayload = Notification["id"];

export type NotificationListPayload = SearchPaginationQuery;
export type NotificationListResponse = ListResponse<Notification> & {
    receivedCount: number;
};

// Routes
export const list: QueryFunction<NotificationListResponse> = async ({ queryKey, pageParam }) => {
    return await api
        .get<NotificationListResponse>(`/notifications`, {
            params: {
                ...(typeof queryKey[2] === "object" ? queryKey[2] : {}),
                page: pageParam ?? 0,
            },
        })
        .then((response) => response?.data);
};

export const read = async (id: NotificationIdPayload) => {
    if (!id) {
        throw new Error("missing id");
    }
    return api.post<Notification>(`/notifications/${id}/read`).then((response) => response?.data);
};

export const readAll = async () => {
    return api.post<Notification>(`/notifications/readAll`).then((response) => response?.data);
};

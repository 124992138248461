import { Cookie as UniversalCookieCookie, CookieSetOptions } from "universal-cookie";

import { addYear, debug } from "./";
import constants from "../config/constants";
import { Cookie } from "../context/CookiesContext";

export type SetCookie = (name: string, value: UniversalCookieCookie, options?: CookieSetOptions) => void;
export type RemoveCookie = (name: string, options?: CookieSetOptions) => void;

const googleTagManagerCookies = ["__utmz", "__utmt", "__utmc", "__utmb", "__utma", "_gat", "_gid", "_ga"];

export const setCookiesOptions: CookieSetOptions = {
    path: "/",
    expires: addYear(new Date()),
};

export const removeCookiesOptions: CookieSetOptions = {
    path: "/",
};

export const acceptAllCookies = (setCookie: SetCookie) => {
    setCookie(Cookie.hasMadeChoice, 1, setCookiesOptions);
    setCookie(Cookie.gtag, 1, setCookiesOptions);
};

const removeGoogleTagManagerScripts = () => {
    const script = [...document.querySelectorAll("script")].find((el) => el.src.includes("googletagmanager"));

    if (script) {
        script.parentNode?.removeChild(script);
    }
};

export const removeGoogleTagManager = (removeCookie: RemoveCookie) => {
    removeGoogleTagManagerScripts();
    delete window.gtag;
    delete window.dataLayer;

    googleTagManagerCookies.forEach((gaCookie) => {
        removeCookie(gaCookie, removeCookiesOptions);
    });
};

declare global {
    interface Window {
        dataLayer?: any[];
        gtag: any;
    }
}

export const addGoogleTagManager = () => {
    if (constants.GOOGLE_ANALYTICS && (!window.dataLayer || !window.gtag)) {
        const head = document.getElementsByTagName("head")[0];
        const script = document.createElement("script");
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${constants.GOOGLE_ANALYTICS}`;
        head.appendChild(script);

        window.dataLayer = window.dataLayer ?? [];

        window.gtag = function () {
            window.dataLayer?.push(arguments);
        };
        window.gtag("js", new Date());
        window.gtag("config", constants.GOOGLE_ANALYTICS, {
            cookie_expires: addYear(new Date()),
        });
    }
};

interface GoogleTagManagerEventParams {
    event_category?: string;
    event_label?: string;
    non_interaction?: boolean;
    send_to?: string;
    transaction_id?: string;
    value?: number;
}

export const analytics = {
    pageView: (pathname: string) => {
        debug.log("[analytics][page view]", pathname);
        if (typeof window.gtag === "function") {
            window.gtag("config", constants.GOOGLE_ANALYTICS, { page_path: pathname });
        } else {
            debug.warn("Attempted to use gtag, but window.gtag is not a function");
        }
    },
    event: (action: string, params: GoogleTagManagerEventParams) => {
        debug.log("[analytics][event]", action, params);
        if (typeof window.gtag === "function") {
            window.gtag("event", action, params);
        } else {
            debug.warn("Attempted to use gtag, but window.gtag is not a function");
        }
    },
};

export const refuseAllCookies = (setCookie: SetCookie, removeCookie: RemoveCookie) => {
    setCookie(Cookie.hasMadeChoice, 1, setCookiesOptions);
    setCookie(Cookie.gtag, 0, setCookiesOptions);

    removeGoogleTagManager(removeCookie);
};

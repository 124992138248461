import { VFC } from "react";
import { useParams } from "react-router-dom";
import { Result } from "antd";
import { ResultProps } from "antd/lib/result";

import Seo from "../../components/Seo";
import ButtonLink from "../../components/ButtonLink";
import { getRoute, RoutePathName } from "../../routes";

interface ErrorPageProps {
    status?: string | number;
}

const ErrorPage: VFC<ErrorPageProps> = ({ status: propsStatus }) => {
    const { 0: param } = useParams<{ 0?: string }>();
    let statusCode = parseInt(param?.substring(1) ?? `${propsStatus ?? ""}`, 10);
    let status: ResultProps["status"] = "error";
    let title: ResultProps["title"] = "Erreur";
    let subTitle: ResultProps["subTitle"] =
        "Nous sommes désolés, une erreur est survenue, veuillez réessayer plus tard ou contacter le support.";

    if (isNaN(statusCode)) {
        statusCode = 404;
    }

    if (`${statusCode}`.length === 3 && `${statusCode}`.startsWith("5")) {
        status = "500";
        title = `${statusCode}`;
    } else if (statusCode === 403 || statusCode === 401) {
        status = "403";
        title = statusCode;
        subTitle =
            statusCode === 403
                ? "Nous sommes désolés, vous n'êtes pas autorisé à accéder à cette ressource."
                : "Nous sommes désolés, vous devez être authentifié pour accéder à cette ressource.";
    } else if (statusCode === 400) {
        title = statusCode;
        subTitle = "Nous sommes désolés, les informations envoyées ne sont pas valides.";
    } else {
        status = "404";
        title = 404;
        subTitle = "Nous sommes désolés, la page que vous cherchez n'existe pas.";
    }

    return (
        <>
            <Seo title={`${statusCode}`} />
            <Result
                status={status}
                title={title}
                subTitle={subTitle}
                extra={
                    <ButtonLink to={getRoute(RoutePathName.home)} type="primary">
                        Retourner à l&rsquo;accueil
                    </ButtonLink>
                }
            />
        </>
    );
};

export default ErrorPage;

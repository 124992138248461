import { Spin } from "antd";
import { VFC } from "react";

import { classNames } from "../helpers";

interface PageSpinnerProps {
    isFixed?: boolean;
}

const PageSpinner: VFC<PageSpinnerProps> = ({ isFixed }) => (
    <div
        className={classNames("flex justify-center items-center", isFixed ? "fixed inset-0 bg-white" : "w-full h-full")}
    >
        <Spin size="large" />
    </div>
);

export default PageSpinner;

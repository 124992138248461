import { AxiosError } from "axios";
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";

import {
    create,
    details,
    list,
    listPermissions,
    remove,
    RoleCreatePayload,
    RoleIdPayload,
    RoleListPayload,
    RoleListPermissionsResponse,
    RoleListReponse,
    RoleUpdatePayload,
    update,
} from "./api/roles";
import { Role } from "./api/types";

export const roleKeys = {
    all: ["roles"],
    lists: () => [...roleKeys.all, "list"],
    list: (params?: RoleListPayload) => [...roleKeys.lists(), params],
    listPermissions: () => [...roleKeys.lists()],
    details: () => [...roleKeys.all, "details"],
    detail: (id?: RoleIdPayload) => [...roleKeys.details(), id],
};

export const useRoleList = <TData = RoleListReponse>(
    params: RoleListPayload | undefined,
    options?: UseQueryOptions<RoleListReponse, AxiosError, TData>
) => {
    return useQuery<RoleListReponse, AxiosError, TData>(roleKeys.list(params), async () => await list(params), {
        keepPreviousData: true,
        ...options,
    });
};

export const useRoleListPermissions = <TData = RoleListPermissionsResponse>() => {
    return useQuery<RoleListPermissionsResponse, AxiosError, TData>(
        roleKeys.listPermissions(),
        async () => await listPermissions()
    );
};

export const useRoleDetails = <TData = Role>(
    id: RoleIdPayload | undefined,
    options?: UseQueryOptions<Role, AxiosError, TData>
) => {
    return useQuery<Role, AxiosError, TData>(roleKeys.detail(id), async () => await details(id), options);
};

export const useRoleCreate = (options?: UseMutationOptions<Role, AxiosError, RoleCreatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Role, AxiosError, RoleCreatePayload>(async (params) => await create(params), {
        ...options,
        onSettled: (...args) => {
            options?.onSettled?.(...args);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(roleKeys.lists());
        },
    });
};

export const useRoleUpdate = (options?: UseMutationOptions<Role, AxiosError, RoleUpdatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Role, AxiosError, RoleUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSettled: (data, error, variables, context) => {
            options?.onSettled?.(data, error, variables, context);

            // invalidate application list query to refetch with the newly added item
            queryClient.invalidateQueries(roleKeys.lists());

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(roleKeys.detail(variables.id));
        },
    });
};

export const useRoleRemove = (options?: UseMutationOptions<undefined, AxiosError, RoleIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, RoleIdPayload>(async (params) => await remove(params), {
        ...options,
        onSettled: (data, error, variables, context) => {
            options?.onSettled?.(data, error, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(roleKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(roleKeys.lists());
        },
    });
};

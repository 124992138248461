import { useState } from "react";

import { useApiInfos } from "../queries";

export type Env = "development" | "preprod" | "production" | undefined;

function useEnv() {
    const [env, setEnv] = useState<Env>(import.meta.env.VITE_ENV as "development" | undefined);
    const { data } = useApiInfos({
        queryKey: ["env"], // declare its own query key to avoid conflict with same api call made in Maintenance.tsx
        staleTime: Infinity,
    });

    if (!env && data) {
        setEnv(data.env);
    }

    return env;
}

export default useEnv;

import { AxiosError } from "axios";
import {
    useInfiniteQuery,
    UseInfiniteQueryOptions,
    useMutation,
    UseMutationOptions,
    useQuery,
    useQueryClient,
    UseQueryOptions,
} from "@tanstack/react-query";

import {
    list,
    NotificationIdPayload,
    NotificationListPayload,
    NotificationListResponse,
    read,
    readAll,
} from "./api/notifications";
import { Notification } from "./api/types";

export const notificationsKeys = {
    all: ["notifications"],
    lists: () => [...notificationsKeys.all, "list"],
    listInfinite: (params?: NotificationListPayload) => [...notificationsKeys.lists(), params],
    list: (params?: NotificationListPayload) => [...notificationsKeys.lists(), params],
};

export const useNotificationList = <TData = NotificationListResponse>(
    params: NotificationListPayload,
    options?: UseQueryOptions<NotificationListResponse, AxiosError, TData>
) => {
    return useQuery<NotificationListResponse, AxiosError, TData>(notificationsKeys.list(params), list, {
        ...options,
        keepPreviousData: true,
    });
};

export const useNotificationListInfinite = <TData = NotificationListResponse>(
    payload: NotificationListPayload,
    options?: UseInfiniteQueryOptions<
        NotificationListResponse,
        AxiosError,
        TData,
        NotificationListResponse,
        Array<string | NotificationListPayload | undefined>
    >
) => {
    return useInfiniteQuery<
        NotificationListResponse,
        AxiosError,
        TData,
        Array<string | NotificationListPayload | undefined>
    >(notificationsKeys.listInfinite(payload), list, options);
};

export const useReadNotification = (options?: UseMutationOptions<Notification, AxiosError, NotificationIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Notification, AxiosError, NotificationIdPayload>(async (params) => await read(params), {
        ...options,
        onSuccess: (...args) => {
            options?.onSuccess?.(...args);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(notificationsKeys.all);
        },
    });
};

export const useReadAllNotifications = (options?: UseMutationOptions<Notification, AxiosError, void>) => {
    const queryClient = useQueryClient();

    return useMutation<Notification, AxiosError, void>(async () => await readAll(), {
        ...options,
        onSuccess: (...args) => {
            options?.onSuccess?.(...args);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(notificationsKeys.all);
        },
    });
};

export class HttpError extends Error {
    public response?: any;
    public status: number;

    constructor(message: string, status: number, response?: any) {
        super(message);

        this.name = this.constructor.name;
        this.response = response;
        this.status = status;
    }
}

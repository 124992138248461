/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M1.667 1.333c0-.368.298-.666.666-.666h11.334a.667.667 0 0 1 0 1.333h-.792c-.437 2-1.007 3.58-1.733 4.698-.347.534-.74.975-1.179 1.303.44.329.832.77 1.18 1.304.725 1.117 1.294 2.696 1.732 4.695h.792a.667.667 0 0 1 0 1.333H2.333a.667.667 0 0 1 0-1.333h.792c.437-2 1.007-3.581 1.733-4.698.347-.534.74-.975 1.179-1.303-.44-.329-.832-.77-1.18-1.304C4.133 5.578 3.564 4 3.126 2h-.792a.667.667 0 0 1-.666-.667M4.492 2c.41 1.778.911 3.088 1.484 3.969.644.99 1.321 1.364 2.025 1.364.702.001 1.38-.371 2.023-1.362C10.597 5.091 11.1 3.78 11.51 2zm3.507 6.667c-.702-.001-1.38.371-2.023 1.362-.573.88-1.075 2.191-1.484 3.971h7.016c-.41-1.778-.911-3.088-1.484-3.969C9.38 9.041 8.703 8.667 8 8.667M6.333 5c0-.368.299-.667.667-.667h2a.667.667 0 0 1 0 1.334H7A.667.667 0 0 1 6.333 5m-.666 7.667c0-.368.298-.667.666-.667h3.334a.667.667 0 0 1 0 1.333H6.333a.667.667 0 0 1-.666-.666"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgHourglassFull: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-hourglass-full", props.className)} />
));

export default SvgHourglassFull;

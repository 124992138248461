import { Permission, RoleSlug, User, PermissionRight } from "../queries/api/types";

export const hasPermission = (user?: User, permission?: Permission, right: PermissionRight = PermissionRight.read) => {
    if (!permission || user?.mergedPermissions?.[Permission.superAdmin] === PermissionRight.write) {
        return true;
    }
    switch (right) {
        case PermissionRight.read:
            return Boolean(user?.mergedPermissions?.[permission]);
        case PermissionRight.write:
            return [PermissionRight.delete, PermissionRight.write].includes(
                user?.mergedPermissions?.[permission] ?? ""
            );
        case PermissionRight.delete:
            return user?.mergedPermissions?.[permission] === PermissionRight.delete;
    }
    return false;
};

export const hasRole = (user?: User, roles?: RoleSlug[]) => {
    return user?.role?.slug && roles?.includes(user.role.slug);
};

export const isSuperAdmin = (user?: User) => {
    return hasRole(user, [RoleSlug.superAdmin]);
};

import { message, MessageArgsProps } from "antd";
import { CheckCircleFilled, CloseCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";

import { classNames } from ".";

export const successMessage = (args: Partial<MessageArgsProps>) => {
    message.success({
        icon: <CheckCircleFilled />,
        ...args,
        className: classNames("custom-message", args.className),
    });
};

export const errorMessage = (args: Partial<MessageArgsProps>) => {
    message.error({
        icon: <CloseCircleFilled />,
        ...args,
        className: classNames("custom-message", args.className),
    });
};

export const warningMessage = (args: Partial<MessageArgsProps>) => {
    message.warning({
        icon: <ExclamationCircleFilled />,
        ...args,
        className: classNames("custom-message", args.className),
    });
};

import { createContext, FC, useMemo, useContext, useCallback, useState } from "react";
import { CookiesProvider as ReactCookiesProvider } from "react-cookie";
import UniversalCookie from "universal-cookie";

const universalCookie = new UniversalCookie();

export enum Cookie {
    hasMadeChoice = "ezee-has_made_choice",
    gtag = "ezee-gtag",
}

export enum CookieStateName {
    hasMadeChoice = "hasMadeChoice",
    gtag = "gtag",
}

interface CookiesContextTypes {
    [CookieStateName.hasMadeChoice]: boolean | null;
    [CookieStateName.gtag]: boolean | null;
    acceptAll: () => void;
    refuse: () => void;
    set: (payload: { name: CookieStateName; value: boolean }) => void;
    toggle: (payload: CookieStateName) => void;
    reset: () => void;
}

export const CookiesContext = createContext<CookiesContextTypes | null>(null);
CookiesContext.displayName = "CookiesContext";

const initialValues = {
    hasMadeChoice:
        universalCookie.get(Cookie.hasMadeChoice) === undefined
            ? null
            : !!parseInt(universalCookie.get(Cookie.hasMadeChoice), 10),
    gtag: universalCookie.get(Cookie.gtag) === undefined ? null : !!parseInt(universalCookie.get(Cookie.gtag), 10),
};

export const CookiesProvider: FC = ({ children }) => {
    const [hasMadeChoice, setHasMadeChoice] = useState(initialValues.hasMadeChoice);
    const [gtag, setGtag] = useState(initialValues.gtag);

    const setterMap = useMemo(() => new Map([[CookieStateName.gtag, setGtag]]), [setGtag]);
    const getterMap = useMemo(() => new Map([[CookieStateName.gtag, gtag]]), [gtag]);

    const acceptAll = useCallback(() => {
        setHasMadeChoice(true);
        setGtag(true);
    }, []);

    const refuse = useCallback(() => {
        setHasMadeChoice(true);
        setGtag(false);
    }, []);

    const set: CookiesContextTypes["set"] = useCallback(
        (payload) => {
            setterMap.get(payload.name)?.(payload.value);
        },
        [setterMap]
    );

    const toggle: CookiesContextTypes["toggle"] = useCallback(
        (payload) => {
            setterMap.get(payload)?.(!getterMap.get(payload));
        },
        [setterMap, getterMap]
    );

    const reset = useCallback(() => {
        setHasMadeChoice(initialValues.hasMadeChoice);
        setGtag(initialValues.gtag);
    }, []);

    const layoutContext = useMemo(
        () => ({
            hasMadeChoice,
            gtag,
            acceptAll,
            refuse,
            set,
            toggle,
            reset,
        }),
        [hasMadeChoice, gtag, acceptAll, refuse, set, toggle, reset]
    );

    return (
        <ReactCookiesProvider cookies={universalCookie}>
            <CookiesContext.Provider value={layoutContext}>{children}</CookiesContext.Provider>
        </ReactCookiesProvider>
    );
};

export const useCookies = () => {
    const context = useContext(CookiesContext);

    if (!context) {
        throw new Error("useCookies must be used within an CookiesProvider");
    }

    return context;
};

import { FC, useRef } from "react";
import { Link, LinkProps, NavLink } from "react-router-dom";
import { Button } from "antd";
import { ButtonProps } from "antd/lib/button";

export type ButtonLinkProps = Omit<LinkProps, "to" | "href"> &
    Omit<ButtonProps, "href"> & {
        isNavLink?: boolean;
        onLinkClick?: LinkProps["onClick"];
    } & (
        | {
              href: string;
              to?: LinkProps["to"];
          }
        | {
              href?: string;
              to: LinkProps["to"];
          }
    );

const ButtonLink: FC<ButtonLinkProps> = ({
    children,
    download,
    href,
    isNavLink,
    disabled,
    onLinkClick,
    target,
    to,
    ...rest
}) => {
    const buttonRef = useRef<HTMLElement>(null);

    if (disabled) {
        return <Button {...rest}>{children}</Button>;
    }

    if (href) {
        return (
            <a
                href={href}
                download={download}
                rel="noopener noreferrer"
                target={target}
                onFocus={() => buttonRef.current?.focus()}
                onBlur={buttonRef.current?.blur}
            >
                <Button tabIndex={-1} {...rest} ref={buttonRef}>
                    {children}
                </Button>
            </a>
        );
    }

    if (to) {
        if (isNavLink) {
            return (
                <NavLink
                    to={to}
                    onClick={onLinkClick}
                    onFocus={() => buttonRef.current?.focus()}
                    onBlur={buttonRef.current?.blur}
                >
                    <Button tabIndex={-1} {...rest} ref={buttonRef}>
                        {children}
                    </Button>
                </NavLink>
            );
        }

        return (
            <Link
                to={to}
                onClick={onLinkClick}
                onFocus={() => buttonRef.current?.focus()}
                onBlur={buttonRef.current?.blur}
            >
                <Button tabIndex={-1} {...rest} ref={buttonRef}>
                    {children}
                </Button>
            </Link>
        );
    } else {
        return null;
    }
};

export default ButtonLink;

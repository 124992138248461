import { FC, useEffect } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { Spin } from "antd";

import { getRawRoute, RoutePathName } from "../routes";
import { useAuth } from "../context/AuthContext";

const ProtectedRoute: FC = ({ children }) => {
    const location = useLocation();
    const { isCheckingSession, checkSessionError, logout, user } = useAuth();

    useEffect(() => {
        const listener = () => {
            if (!checkSessionError) {
                logout.mutate();
            }
        };
        // dispatched from axios interceptor in api/apiClient.ts
        window.addEventListener("unauthorized.error", listener);

        return () => window.removeEventListener("unauthorized.error", listener);
    }, [checkSessionError]); // eslint-disable-line react-hooks/exhaustive-deps

    if (isCheckingSession) {
        return (
            <Route>
                <div id="initial-loader">
                    <Spin size="large" />
                </div>
            </Route>
        );
    } else if (checkSessionError ?? !user) {
        return (
            <Redirect
                to={{
                    pathname: getRawRoute(RoutePathName.login),
                    state: { from: location },
                }}
            />
        );
    } else {
        return <>{children}</>;
    }
};

export default ProtectedRoute;
